@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'DM Sans', sans-serif;
}

.btn-green {
  @apply rounded-3xl bg-green-500 whitespace-nowrap py-2 px-5 text-black text-base outline-none border-none cursor-pointer transition ease-in-out duration-200 no-underline hover:bg-white hover:text-black;
}
.carousel-item {
  margin-right: 20px; /* Adjust the value to control the amount of space between items */
}

.carousel-item:last-child {
  margin-right: 0; /* Remove margin for the last child to prevent extra space */
}
.clip-svg {
  clip-path: url(#svgMask);
}
.leading-100 {
  line-height: 100%;
}

.leading-105 {
  line-height: 105% !important;
}

.leading-110 {
  line-height: 110% !important;
}

.leading-120 {
  line-height: 120% !important;
}

.leading-130 {
  line-height: 130% !important;
}

.leading-140 {
  line-height: 140% !important;
}

.leading-150 {
  line-height: 150% !important;
}

.tracking-128 {
  letter-spacing: -1.28px;
}

.tracking-64 {
  letter-spacing: -0.64px;
}

.tracking-96 {
  letter-spacing: -0.96px;
}

.tracking-160 {
  letter-spacing: -1.6px;
}
.tracking-456 {
  letter-spacing: -4.56px;
}

.tracking-320 {
  letter-spacing: -0.32px;
}

.filter-white {
  filter: brightness(0) invert(1);
}

.filter-blue {
  filter: brightness(0) saturate(100%) invert(31%) sepia(66%) saturate(345%) hue-rotate(173deg) brightness(98%) contrast(95%);
}

.filter-black {
  filter: invert(100%);
}

.text-gray-450 {
  color: #4B4B4B;
}

.border-gray-320 {
  border-color: #C7C7C7;
}

.border-blue-420 {
  border-color: #CDDFFA;
}